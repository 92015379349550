export class FileDetails {
    formField: string;
    file: File
}

export const FileFormFields = {
    mietwagenrechnung: 'mietwagenrechnungDatei',
    kaufvertrag: 'kaufvertragDatei',
    werkstattrechnung: 'werkstattrechnungDatei',
    honorarkosten: 'honorarkostenDatei',
    abmeldekosten: 'abmeldekostenDatei',
    standkosten: 'standkostenDatei',
    benzinSchaden: 'benzinSchadenDatei',
    vorhalteKosten: 'vorhalteKostenDatei',
    fahrbereinigung: 'fahrbereinigungDatei',
    schmerzensgeld: 'schmerzensgeldDatei',
    heilbehandlungsKosten: 'heilbehandlungsKostenDatei',
    transportKosten: 'transportKostenDatei',
    gewinn: 'gewinnDatei',
    erwerbsSchaden: 'erwerbsSchadenDatei',
    fahrtKosten: 'fahrtKostenDatei',
    haushaltsfuehrungsSchaden: 'haushaltsSchadenDatei',
    aufwendungen: 'aufwendungenDatei',
    rueckstufungsKosten: 'rueckstufungsKostenDatei',
    beleg: 'beleg',
    polizeibericht: 'polizeiberichtDatei',
    svgutachten: 'svgutachtenDatei',
    vollmacht: 'vollmachtDatei'
}
