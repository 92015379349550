import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { MandantenService } from '../mandanten/mandanten.service';
import { UserService } from '../userlogin/user.service';
import { configData } from '../../config.data';
import { HttpEvent, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Mandat } from 'src/app/models/mandat';
import { Observable } from 'rxjs';
import { HandleError, HttpErrorHandler } from 'src/app/services/http-error-handler.service';
import { FileDetails } from './createmandat/file-class';

@Injectable({
  providedIn: 'root'
})
export class MandateService {
  private handleError: HandleError;
  constructor(private http: HttpClient, private router: Router,
    private user: UserService, httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('MandantenService');
  }

  expressLink = configData.SERVER + 'mandat/';

  // Create a new Mandat
  createMandat(body, files: FileDetails[], mandantID) {
    const formData = new FormData();
    formData.append('mandat', body);
    files.forEach(obj => {
      formData.append(obj.formField, obj.file);
    })
    return this.http.post(this.expressLink + 'mandat/' + mandantID + '/?autor=' + this.user.loggedinUser, formData, {
      observe: 'body',
      // headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }
  // Update Mandat by Id
  saveMandat(body, files: FileDetails[], mandatID, mandantID) {
    const formData = new FormData();
    formData.append('mandat', body);
    files.forEach(obj => {
      formData.append(obj.formField, obj.file);
    })
    return this.http.put((this.expressLink + 'mandat/' + mandatID + '/' + mandantID + '/?autor=' + this.user.loggedinUser), formData, {
      observe: 'body',
      // headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }
  // Get one Mandat

  viewOneMandat(mandatID) {
    return this.http.get(this.expressLink + 'mandate/' + mandatID, {
      observe: 'body',
      withCredentials: true, // Make cookie available in browser
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }
  // Get all mandate
  viewMandate() {
    return this.http.get(this.expressLink + 'mandate', {
      observe: 'body',
      withCredentials: true, // Make cookie available in browser
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }
  // Get all mandate by View
  viewMandateList() {
    return this.http.get(this.expressLink + 'mandateView', {
      observe: 'body',
      withCredentials: true, // Make cookie available in browser
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }
  // Get Mandats of one Mandant
  viewMandateofMandant(mandantID) {
    return this.http.get(this.expressLink + 'mandate/mandant/' + mandantID, {
      observe: 'body',
      withCredentials: true, // Make cookie available in browser
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }
  // Get Mandats by date range
  searchMandate(startDate, endDate) {
    return this.http.get(this.expressLink + 'mandate/viewbydate/' + startDate + '/' + endDate, {
      observe: 'body',
      withCredentials: true, // Make cookie available in browser
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }
  // Delete the Mandat
  deleteMandat(id) {
    return this.http.delete(this.expressLink + 'deletemandat/' + id + '/?autor=' + this.user.loggedinUser, {
      observe: 'body',
      withCredentials: true, // Make cookie available in browser
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }

  // Get all fahrzeugklasse
  // listFahrzeugklasse() {
  //   return this.http.get(this.expressLink + 'fahrzeugklasseNames', {
  //     observe: 'body',
  //     withCredentials: true, // Make cookie available in browser
  //     headers: new HttpHeaders().append('Content-Type', 'application/json')
  //   });
  // }

  // Get Mandats of one Partner
  viewMandateofPartner(partnerID) {
    return this.http.get(this.expressLink + 'mandate/partner/' + partnerID, {
      observe: 'body',
      withCredentials: true, // Make cookie available in browser
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }

  // Get Mandats of one kfzPartner
  viewMandateofKFZPartner(partnerID) {
    return this.http.get(this.expressLink + 'mandate/kfzpartner/' + partnerID, {
      observe: 'body',
      withCredentials: true, // Make cookie available in browser
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }

  // Get Mandats of one werkstattPartner
  viewMandateofWPartner(partnerID) {
    return this.http.get(this.expressLink + 'mandate/wpartner/' + partnerID, {
      observe: 'body',
      withCredentials: true, // Make cookie available in browser
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }

  // Get Mandats of one Innsurer
  viewMandateofInsurer(insurerName) {
    return this.http.get(this.expressLink + 'mandate/insurer/' + insurerName, {
      observe: 'body',
      withCredentials: true, // Make cookie available in browser
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }

  // Get Mandats of one User
  viewMandateofUser() {
    return this.http.get(this.expressLink + 'mandate/user/' + this.user.loggedinUser, {
      observe: 'body',
      withCredentials: true, // Make cookie available in browser
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }

  // Get Mandats by Status
  viewMandatebyStatus(status) {
    return this.http.get(this.expressLink + 'mandate/status/' + status, {
      observe: 'body',
      withCredentials: true, // Make cookie available in browser
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }

  // Upload files

  upload(data: FormData, mandatID) {
    return this.http.post<any>((this.expressLink + 'mandate/' + mandatID + '/fileUpload/'), data, {
      reportProgress: true,
      observe: 'events'
    }).pipe(map((event) => {
      switch (event.type) {
        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * event.loaded / event.total);
          return { status: 'progress', message: progress };
        case HttpEventType.Response:
          return event.body;
        default:
          return `Unhandled event: ${event.type}`;
      }
    })
    );
  }

  // get uploaded file of a mandat
  getFileUpload(mandatID, fileName) {
    return this.http.get(this.expressLink + 'mandat/getFile/' + mandatID + '/' + fileName, {
      responseType: 'blob',
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }

  downloadPDF(mandatID) {
    const body = { filename: 'test.pdf' }; // Just defining for the purpose of post
    return this.http.post(this.expressLink + 'pdf/' + mandatID, body, {
      // observe: 'body',
      responseType: 'blob',
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }

  // Send first Claim letter
  sendClaim(mandatID): Observable<Mandat> {
    return this.http.get<Mandat>(this.expressLink + 'submitClaim/' + mandatID + '/?autor=' + this.user.loggedinUser, {
      observe: 'body',
      withCredentials: true, // Make cookie available in browser
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }
  // HonorarClaim
  honorarClaim(mandatID): Observable<Mandat> {
    return this.http.get<Mandat>(this.expressLink + 'honorarClaim/' + mandatID + '/?autor=' + this.user.loggedinUser, {
      observe: 'body',
      withCredentials: true, // Make cookie available in browser
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }
}
