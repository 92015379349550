import { Injectable } from '@angular/core';
import { CanActivate, Router, CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from '../components/userlogin/user.service';
import { Observable } from 'rxjs';

export interface CanComponentDeactivate {
  // canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
  confirm(): boolean;
}

@Injectable()
export class ConfirmGuard implements CanDeactivate<CanComponentDeactivate> {
  constructor(private router: Router, private user: UserService) { }

  canDeactivate(
    component: CanComponentDeactivate,
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // if there are no pending changes, just allow deactivation; else confirm first
    console.log(state);
    return component.confirm(); // ?
    // true :
    // NOTE: this warning message will only be shown when navigating elsewhere within your angular app;
    // when navigating away from your angular app, the browser will show a generic warning message
    // see http://stackoverflow.com/a/42207299/7307355
    // confirm('WARNING: You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.');
  }

}
