import { Directive, HostListener, HostBinding } from '@angular/core';


@Directive({
  selector: '[appDropdown]'
})
export class DropdownDirective {
  @HostBinding('class.open') isOpen = false;
/*   @HostListener('mouseout') onMouseOver() {
    this.toggleOpen();
  }
  @HostListener('mouseover') toggleOpen() {
    this.isOpen = !this.isOpen;
  } */
  @HostListener('click') onMouseClick() {
    this.isOpen = !this.isOpen;
  }
}
